import { render, staticRenderFns } from "./detailBusiness.vue?vue&type=template&id=332b0929&scoped=true&"
import script from "./detailBusiness.vue?vue&type=script&lang=js&"
export * from "./detailBusiness.vue?vue&type=script&lang=js&"
import style0 from "./detailBusiness.vue?vue&type=style&index=0&id=332b0929&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "332b0929",
  null
  
)

export default component.exports