<template>
  <!-- 这里是“造价预算业务”详情页面 -->
  <div class="mb-50">
    <div class="list-z">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="fs-18">
        <el-breadcrumb-item :to="{ path: '/engineering/index' }"
          >首页</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/creative-personnel/index' }"
          >造价人员</el-breadcrumb-item
        >
        <el-breadcrumb-item>详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="main">
      <el-row type="flex" align="middle" style="margin-bottom: 70px">
        <el-col :span="24">
          <div class="line" style="padding: 20px 0px">
            <div class="name-t">{{ model.contact || "-" }}</div>
            <div class="a-flex flex-between">
              <div class="a-flex">
                <div class="back1 mr-10">{{ model.workAge || "-" }}</div>
                <div class="back1">{{ model.startdate || "-" }}</div>
              </div>
              <!--<div>
                【阅读次数：<span>{{ model.txt1 }}</span> 】
              </div>-->
            </div>
          </div>
        </el-col>
      </el-row>
      <!-- 联系信息 -->
      <el-row>
        <el-col :span="24">
          <div class="line line-top a-flex flex-center">
            <div class="border-left"></div>
            <div class="title">联系信息</div>
          </div>
        </el-col>
      </el-row>
      <el-row class="basic mb-80">
        <el-col :span="12" class="text-left">
          <div class="mb-40">
            人员姓名：<span>{{ model.contact || "-" }}</span>
          </div>
          <div>
            联系地址：<span>{{ model.address || "-" }}</span>
          </div>
        </el-col>
        <el-col :span="12" class="text-left">
          <div class="a-flex mb-40">
            <div>
              联系电话：<span>{{ isContactNumber ? '***********' : model.contactNumber }}</span>
            </div>
            <div class="view" @click="isContactNumber = false"><i class="el-icon-view" style="padding-right: 4px;"></i>查看</div>
          </div>
        </el-col>
      </el-row>
      <!-- 人员资质 -->
      <el-row>
        <el-col :span="24">
          <div class="line line-top a-flex flex-center">
            <div class="border-left"></div>
            <div class="title">人员资质</div>
          </div>
        </el-col>
      </el-row>
      <el-row class="basic mb-80">
        <el-col :span="24" class="mb-40">
          <el-col :span="12" class="text-left">
            <div class="fs-16">
              注册等级：<span>{{ model.category === 'costEstimator' ? '造价员' : 'costEngineer' ? '预算员' : 'logonCostEngineer' ? '一级注册造价工程师' : '2logonCostEngineer' ? '二级注册造价工程师' : '不限' }}</span>
            </div>
          </el-col>
          <el-col :span="12" class="text-left">
            <div class="fs-16">
              经验要求：<span>{{ model.workAge || "-" }}</span>
            </div>
          </el-col>
        </el-col>
        <el-col :span="24" class="mb-40 text-left">
          <div class="fs-16">
            <div class="a-flex">
              <div>需求类型：</div>
              <div class="bor-b mr-10">{{ model.needBusiness }}</div>
            </div>
          </div>
        </el-col>
        <el-col :span="24" class="mb-40 text-left">
          <div class="fs-16">
            要求专业：<span>{{ model.specialized || "-" }}</span>
          </div>
        </el-col>
        <el-col :span="24" class="mb-40 text-left">
          <div class="fs-16">
            需求描述：<span>{{ model.describes || "-" }}</span>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { getBusinessDetail, getBudgetPerson } from "@/api/engineering";
export default {
  data() {
    return {
      expandedRows: [],
      model: {},
      isContactNumber: true
    };
  },
  computed: {},
  mounted() {
    this.getBusinessDetail(this.$route.query.id);
  },
  methods: {
    async getBusinessDetail(id) {
      const param = {
        id: id,
      };
      // 获取API
      const res = await getBusinessDetail(param);
      this.model = res.data;
    },
  },
};
</script>
<style scoped>
.a-flex {
  display: flex;
}
/* 上下左右 */
.flex-between {
  justify-content: space-between;
  align-items: center;
}
/* 垂直 */
.flex-center {
  align-items: center;
}
.main {
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  color: #4e4e4e;
}
.list-z {
  text-align: left;
  font-weight: 500;
  color: #4e4e4e;
  line-height: 40px;
  margin-top: 25px;
  margin-bottom: 25px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-80 {
  margin-bottom: 80px;
}
.fs-18 {
  font-size: 18px;
}
.fs-16 {
  font-size: 16px;
}

.mr-10 {
  margin-right: 12px;
}
.line {
  border-bottom: 1px solid #dcdcdc;
}
.name-t {
  font-size: 20px;
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  font-weight: bold;
  color: #212121;
  text-align: left;
  margin-bottom: 20px;
}
.back1 {
  height: 28px;
  background: #f6f7fb;
  border-radius: 4px 4px 4px 4px;
  font-size: 16px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  padding: 0px 10px;
  line-height: 28px;
  color: #4e4e4e;
}
.border-left {
  width: 10px;
  height: 16px;
  background: #007ef2;
}
.title {
  font-size: 24px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #4e4e4e;
  margin-left: 10px;
}
.line-top {
  padding-bottom: 20px;
  margin-bottom: 40px;
}
.text-left {
  text-align: left;
}
.basic {
  font-size: 16px;
  font-weight: 400;
  color: #4e4e4e;
}

.view {
  font-size: 16px;
  font-weight: 400;
  color: #007ef2;
  margin-left: 20px;
  cursor: pointer;
}
.bor-b {
  height: 26px;
  padding: 0 8px;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #1890ff;
  text-align: center;
  color: #1890ff;
}
</style>
